@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

html {
  background-color: #f9fafb;
  height: 100%;
}

#root {
  background-color: #f9fafb;
  height: 100%;
}

/* Removes default header and footer when printing */
@page {
  margin: 0 auto;
}

/* By default the print view doesn't let background styles be applied
https://stackoverflow.com/questions/6670151/how-can-i-force-browsers-to-print-background-images-in-css */
.print-bg {
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
}
